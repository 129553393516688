import axios from "axios";

export const setEditor = ({ editorRef, selectedFile, setSelectedFile, comments, setComments, setShowComment, setShowAllComments, setSelection }) => {
  window.require(['vs/editor/editor.main'], initializeEditor);

  function initializeEditor() {   

    if (!editorRef.current || !selectedFile || !window.monaco) return;

    let language = selectedFile.name.split('.').pop();
    if (language === "js" || language === "jsx") language = "javascript";
    else if (language === "ts" || language === "tsx") language = "typescript";

    const monacoEditor = createMonacoEditor(editorRef.current, selectedFile.content, language);
   

    const path = (selectedFile.path ? selectedFile.path + '/' : '') + selectedFile.name;
    const pageComment = filterCommentsByPath(comments, path);
   
    pageComment.forEach(comment => {
      if(comment.position === null) return;
      const randomNumber = Math.floor(Math.random() * 50);      
      const decorationOptions = createDecorationOptions(comment.body, `color-${randomNumber}`);
      const start = comment.original_start_line || comment.original_line;      
      const ranges = [new window.monaco.Range(start, 1, comment.original_line, 1)];

      ranges.forEach(range => {        
        monacoEditor.deltaDecorations([], [{ range, options: decorationOptions }]);
      });
    });

    // if(window.addActions) {
    //   return
    // }
    // window.addActions = 1
    showCommentAction(monacoEditor)
    addCommentAction(monacoEditor);
    addSaveFileAction({monacoEditor, selectedFile, setSelectedFile})
    addAutoCheckAction(monacoEditor, path)
    window.monacoEditor = monacoEditor
  }

  

  function filterCommentsByPath(comments, path) {
    return comments.filter(v => v.path === path);
  }

  function createDecorationOptions(body, className) {

    return {
      className: 'review-decoration',
      isWholeLine: true,
      linesDecorationsClassName: "myLineDecoration "+className,
      hoverMessage: [{ value: body, isTrusted: true }]
    };
  }


  function addCommentAction(monacoEditor) {
    monacoEditor.addAction({
      id: 'add_comment',
      label: 'Add comment',
      keybindings: [
        window.monaco.KeyMod.CtrlCmd | window.monaco.KeyCode.KEY_M
      ],
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: function (ed) {
        const selection = monacoEditor.getSelection();
        setShowComment(true);        
        setSelection(selection);
      }
    });
  }

  function showCommentAction(monacoEditor) {
    monacoEditor.addAction({
      id: 'show_comments',
      label: 'Show All Page Comments',
      
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: function (ed) {
        window.parent.postMessage('close-app', '*');
        setTimeout(()=>setShowAllComments(true), 100)
        
      }
    });
  }

  const getAllComments = async () => {
    const { data } = await axios(process.env.REACT_APP_BASE_URL + '/getAllComments', { withCredentials: true })
    console.log(data);
    setComments(data)
  }

  function addAutoCheckAction(monacoEditor, path) {
    monacoEditor.addAction({
      id: 'auto_check',
      label: 'Auto Check',
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: async function (ed) {
        window.parent.postMessage('gpt-checking', '*');
        try {

          const { data } = await axios(process.env.REACT_APP_BASE_URL + '/autoReview?path=' + path, {
            withCredentials: true
          })
          
          await getAllComments()       
          window.parent.postMessage('gpt-checking-done', '*');
        } catch(e){
          window.parent.postMessage('gpt-checking-fail', '*');
          console.log(e);
        } 
      }
    });

    monacoEditor.addAction({
      id: 'auto_check_project',
      label: 'Auto Check Project',
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: async function (ed) {
        window.parent.postMessage('gpt-checking', '*');
        try {
          const project = getQueryParam('repo').split('/').pop().replace('.git', '');
          const { data } = await axios(process.env.REACT_APP_BASE_URL + '/single?project=' + project, {
            withCredentials: true
          })
          
          await getAllComments()       
          window.parent.postMessage('gpt-checking-done', '*');
        } catch(e){
          window.parent.postMessage('gpt-checking-fail', '*');
          console.log(e);
        } 
      }
    });
  }

  function getQueryParam(name) {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }
  function addSaveFileAction({monacoEditor, selectedFile, setSelectedFile}) {
    monacoEditor.addAction({
      id: 'save_file',
      label: 'Save File',
      contextMenuGroupId: 'navigation',
      contextMenuOrder: 1.5,
      run: async function (ed) {                
        await  saveFile({selectedFile, setSelectedFile})
      }
    });
  }

};

export async function saveFile({selectedFile, setSelectedFile}){    
  const content = window.monacoEditor.getValue(); 
  const filePath =  (selectedFile.path ? selectedFile.path + '/' : '') + selectedFile.name; 
  let newfile = selectedFile
  newfile.content = content  
  setSelectedFile(newfile)
  try {        
    const response = await axios.post(process.env.REACT_APP_BASE_URL+'/saveFile', 
              { content, filePath }, 
              {withCredentials: true});    
              
              window.parent.postMessage('refresh-app', '*');
      console.log('File saved successfully', response.data);
  } catch (error) {
    alert('Error saving file');
  }
}


export const  createMonacoEditor = (element, value, language) =>{    
  
  // if(element.id==="mainEditor" && window.MainIditorInstance) {       
  //   const editor = window.monaco.editor.getModels()[0]
  //   editor.setValue(value)    
  //   return window.MainIditorInstance; 
  // }

  // if(element.id==="mainEditor" &&  !window.MainIditorInstance) {
  //  window.MainIditorInstance =  window.monaco.editor.create(element, { value, language, theme: "vs-dark" });
  //  return window.MainIditorInstance
  // }
  return window.monaco.editor.create(element, {
    value,
    language,
    theme: "vs-dark",
  });
}
