import React, { useContext, useEffect, useRef, useState } from 'react';

import axios from 'axios';
import { Comments } from '../../CommentsContext';
import 'prismjs/themes/prism.css';
import { createMonacoEditor } from '../../utils/editor-helper';

const extractPlainTextWithNewLines = (html) => {
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = html;
    
    // Replace <br> and <p> tags with newline characters
    tempDiv.querySelectorAll('br').forEach(br => br.replaceWith('\n'));
    tempDiv.querySelectorAll('p').forEach(p => p.textContent += '\n');
    
    return tempDiv.textContent || tempDiv.innerText || '';
  };

const useCommentItem = ({ setShowComment, lines, selectedFile, body, comment, windowSize }) => {
    const [isSending, setIsSending] = useState(false);
    const [editorData, setEditorData] = useState(body);
    const smallEditorRef = useRef()    
    const { setComments, } = useContext(Comments)
    const isPageComments = body !== undefined
    const sendComment = async (e) => {
        setIsSending(true)
        const body = editorData;
        const path = selectedFile.path + '/' + selectedFile.name;
        let fixedLines = lines;
        
        if(!lines[1] || lines[1]<=lines[0]){
            fixedLines = lines[0] > 1 ? [lines[0]-1, lines[0]]: [lines[0], lines[0]+1]
        }
        let id = null
        if(comment && comment.id) {
            id = comment.id
        }
        try {         
            
            await axios.post(process.env.REACT_APP_BASE_URL + '/createReviewComment', { lines:fixedLines, body, path, id}, {
                withCredentials: true
            });
            setShowComment(false)
        } catch (e) {
        } finally {
            const { data } = await axios(process.env.REACT_APP_BASE_URL + '/getAllComments', {
                withCredentials: true
            }) 
            console.log(data);
            setIsSending(false)
            setComments(data)
        }

    };

    const translate = async (improve) => {
        setIsSending(true)
        try {
            const text = editorData;
            const filepath = selectedFile.path + '/' + selectedFile.name;
            const { data } = await axios.post(process.env.REACT_APP_BASE_URL + '/translate', { text, lines, filepath, improve }, {
                withCredentials: true
            })
            setEditorData(data)
        } catch(e){
            console.log(e);
        } finally {
            setIsSending(false)            
        }
        

    }

    function removeGitAddedCode(gitDiff) {
        return gitDiff
        .split('\n')  // Split the string by lines
        .map(line => {
            if (line.startsWith('+') || line.startsWith('-')) {
                return line.slice(1);  // Remove the first character (+ or -) from the line
            }
            return line;
        })
        .filter(line => !line.startsWith('@@'))  // Remove lines starting with @@
        .join('\n');  // Join the lines back to a single string
    }

    const deleteComment = async () => {
        if (!isPageComments) {
            setShowComment(false)
            return
        }
        setIsSending(true)
        const body = editorData;
        const path = selectedFile.path + '/' + selectedFile.name;
        const id = comment.id
        
        const params = { lines, body, path, id }
        const apiUrl = process.env.REACT_APP_BASE_URL + '/removeCommentLocally'
        const { data } = await axios.post(apiUrl, params, {
            withCredentials: true
        })
        setComments(data)
        setIsSending(false)
    }

    
    useEffect(() => {                
        let monacoEditor = null
        if(comment){
            
            const val = comment.diff_hunk? removeGitAddedCode(comment.diff_hunk) : comment.code;
            monacoEditor = createMonacoEditor(smallEditorRef.current, val, "javascript" );
            const lastLine = monacoEditor.getModel().getLineCount();
            monacoEditor.revealPosition({ lineNumber: lastLine, column: 1 });                            
        }
        return ()=>{
            if(!monacoEditor) {
                return
            }
            monacoEditor.dispose()
            
        }
    }, [windowSize, comment]);

    return { smallEditorRef, isSending, setIsSending, editorData, setEditorData, isPageComments, lines, sendComment, comment, selectedFile, translate, deleteComment }
}

export default useCommentItem