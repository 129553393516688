import React, { ReactNode } from 'react';
import { SiHtml5, SiCss3, SiJavascript, SiTypescript, SiJson } from "react-icons/si";
import { FcFolder, FcOpenedFolder, FcPicture, FcFile } from "react-icons/fc";
import { AiFillFileText } from "react-icons/ai";

function getIconHelper() {
  const cache = new Map();
  cache.set("js", React.createElement(SiJavascript, { color: "#fbcb38" }));
  cache.set("jsx", React.createElement(SiJavascript, { color: "#fbcb38" }));
  cache.set("ts", React.createElement(SiTypescript, { color: "#378baa" }));
  cache.set("tsx", React.createElement(SiTypescript, { color: "#378baa" }));
  cache.set("css", React.createElement(SiCss3, { color: "purple" }));
  cache.set("json", React.createElement(SiJson, { color: "#5656e6" }));
  cache.set("html", React.createElement(SiHtml5, { color: "#e04e2c" }));
  cache.set("png", React.createElement(FcPicture, null));
  cache.set("jpg", React.createElement(FcPicture, null));
  cache.set("ico", React.createElement(FcPicture, null));
  cache.set("txt", React.createElement(AiFillFileText, { color: "white" }));
  cache.set("closedDirectory", React.createElement(FcFolder, null));
  cache.set("openDirectory", React.createElement(FcOpenedFolder, null));
  return function (extension, name) {
    if (cache.has(extension))
      return cache.get(extension);
    else if (cache.has(name))
      return cache.get(name);
    else
      return React.createElement(FcFile, null);
  };
}

export const getIcon = getIconHelper();
