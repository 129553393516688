import React from 'react';
import { Puff } from 'react-loader-spinner';
import useCommentItem from './UseCommentItem';
import CKEditorLocal from '../CKEditorLocal';


function CommentItem(props) {
    const { smallEditorRef, isSending, isPageComments, sendComment, lines, translate, deleteComment, editorData, setEditorData, comment, selectedFile } = useCommentItem(props);
    const showButtons = (isPageComments && !comment._links) || !isPageComments;
    return (
        <div className={"comment is-loading-" + isSending + " show-all-comments-" + isPageComments}>
            {isSending && <Puff type="Puff" color="#00BFFF" className="centered-spinner align-center" height={100} width={100} />}
           
            <div className="file">
                {selectedFile.name}
            </div>
            <div className="lines">
                LINES: {lines[0] + ' - ' + (lines[1] || lines[0])}
            </div>
            {comment && <>
                {comment.importance && <div className="lines">Importancy: {comment.importance} </div>}
                {comment.checking && <div className="lines">checking: {comment.checking} </div>}                
                    </>
                }
            {comment && <div ref={smallEditorRef} className="small-editor" />}
            <div className={"comment-body "+(!isPageComments && 'big-ck')}>
                <CKEditorLocal data={editorData}
                    onChange={(event, editor) => {
                        setEditorData(editor.getData())
                    }} />
            </div>
            {
                showButtons ?
                    <div className="buttons">
                        <button className="btn-submit btn" onClick={() => sendComment()}>
                            <i className="fas fa-check"></i>
                        </button>
                        <button className="btn-close btn" onClick={() => deleteComment()}>
                            <i className="fas fa-times"></i>
                        </button>
                        <button className="btn-submit btn" onClick={() => translate(0)}>
                            <i className="fas fa-globe"></i>
                        </button>
                        {/* <button className="btn-submit btn" onClick={() => translate(1)}>
                            <i className="fas fa-magic"></i>
                        </button> */}
                    </div>
                    :
                    <div className="buttons">
                        <a href={comment._links.html.href} className="btn-submit btn" target='_blank'>
                            <i className="fab fa-git"></i>
                        </a>
                    </div>
            }
        </div>
    );
}

export default CommentItem;
