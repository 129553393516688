
export function buildFileTree(data) {
  const dirs = [...data.directories]; 
  const files = [...data.modules]; 
  const cache = new Map(); 
  
  let rootDir = {
    id: "0",
    name: "root",
    parentId: undefined,
    type: "DIRECTORY",
    depth: 0,
    dirs: [],
    files: []
  };

  dirs.forEach((item) => {
    let dir = {
      id: item.shortid,
      name: item.title,
      parentId: item.directory_shortid === null ? "0" : item.directory_shortid,
      type: "DIRECTORY",
      depth: 0,
      dirs: [],
      files: []
    };
    cache.set(dir.id, dir);
  });

  files.forEach((item) => {
    let file = {
      id: item.shortid,
      name: item.title,
      parentId: item.directory_shortid === null ? "0" : item.directory_shortid,
      type: "FILE",
      depth: 0,
      content: item.code
    };
    cache.set(file.id, file);
  });

  cache.forEach((value, key) => {

    if (value.parentId === "0") {
      if (value.type === "DIRECTORY") rootDir.dirs.push(value);
      else rootDir.files.push(value);
    } else {
      const parentDir = cache.get(value.parentId);
      if (value.type === "DIRECTORY") parentDir.dirs.push(value);
      else parentDir.files.push(value);
    }
  });


  getDepth(rootDir, 0);

  return rootDir;
}


function getDepth(rootDir, curDepth) {
  rootDir.files.forEach((file) => {
    file.depth = curDepth + 1;
  });
  rootDir.dirs.forEach((dir) => {
    dir.depth = curDepth + 1;
    getDepth(dir, curDepth + 1);
  });
}

export function findFileByName(rootDir, filename) {
  let targetFile;

  function findFile(rootDir, filename) {
    rootDir.files.forEach((file) => {
      if (file.name === filename) {
        targetFile = file;
        return;
      }
    });
    rootDir.dirs.forEach((dir) => {
      findFile(dir, filename);
    });
  }

  findFile(rootDir, filename);
  return targetFile;
}

export function sortDir(l, r) {
  return l.name.localeCompare(r.name);
}

export function sortFile(l, r) {
  return l.name.localeCompare(r.name);
}
