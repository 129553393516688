// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;

  background-color: #151515;
  color: white;
  zoom: 1;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,gCAAgC;EAChC,eAAe;;EAEf,yBAAyB;EACzB,YAAY;EACZ,OAAO;EACP,SAAS;AACX","sourcesContent":["body {\n  font-family: 'Inter', sans-serif;\n  font-size: 14px;\n\n  background-color: #151515;\n  color: white;\n  zoom: 1;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
