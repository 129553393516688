import React, { useContext, useEffect, useRef, useState } from 'react';
import Prism from 'prismjs';
import 'prismjs/themes/prism.css';
import axios from 'axios';
import { Puff } from 'react-loader-spinner';
import { Comments } from '../CommentsContext';
import FilterButtons from './FilterButtons/FilterButtons';
import CommentItem from './CommentItem/CommentItem';



export default function CommentPopup({ setShowComment, selection, selectedFile, comments, windowSize }) {

  const path = (selectedFile.path ? selectedFile.path + '/' : '') + selectedFile.name;

  const [filter, setFilter] = useState((v) => v => v.path === path)
  
  const { setComments, showAllComments, setShowAllComments } = useContext(Comments)
  const derivedComments = comments?.filter((v) => v => v.path === path)
  
  useEffect(() => {
    window.parent.postMessage('close-app', '*');
    return () => {

      window.parent.postMessage('open-app', '*');
    }
  }, [])

  return (
    <div className="review-popup">

      <div className="popup-header">
        
        {(comments) ? <>
          <button className="btn-close" onClick={() => setShowAllComments(false)}>
            <i className="fas fa-times"></i>
          </button>
          <h2>Page comments ({comments.filter(filter).length})</h2>
          {comments.length && <FilterButtons {...{ setFilter, path, }} />}
        </>:
        <>
        <button className="btn-close" onClick={() =>  setShowComment(false)}>
          <i className="fas fa-times"></i>
        </button>
        <h2>Add a Code Review Comment</h2>
        </>    
      }   
      </div>
      <div className="popup-body">

        {(comments) ?
          comments.filter(c=>c.path===path).map(comment => <CommentItem key={comment.original_start_line + '-' + comment.original_line + comment.body[0] + comment.id}
            {...{ setShowComment, selectedFile, lines: [comment.original_start_line, comment.original_line], body: comment.body, comment, windowSize }}
          />)
          : <CommentItem
            {...{ setShowComment, selectedFile, windowSize }}
            lines={[selection.startLineNumber, selection.endLineNumber]}
          />
        }
      </div>
    </div>
  )
}
