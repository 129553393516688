import React, { useState } from 'react';
import { sortDir, sortFile } from "../utils/file-manager";
import { getIcon } from "./icon";
import styled from "@emotion/styled";
import axios from 'axios';
import { ContextMenu } from './ContextMenu/ContextMenu';

export const FileTree = ({ rootDir, selectedFile, onSelect }) => {
  return <SubTree directory={rootDir} selectedFile={selectedFile} onSelect={onSelect} />;
};

const SubTree = ({ directory, selectedFile, onSelect }) => {  
  return (
    <div>
      {directory.dirs
        .sort(sortDir)
        .map((dir) => (
          <React.Fragment key={dir.id}>
            <DirDiv directory={dir} selectedFile={selectedFile} onSelect={onSelect} />
          </React.Fragment>
        ))}
      {directory.files
        .sort(sortFile)
        .map((file) => (
          <React.Fragment key={file.id}>
            <ContextMenu isDir={false} {...{ directory, path: directory.path+'\\'+file.name}} >
              <FileDiv file={file} selectedFile={selectedFile} onClick={() => onSelect(file)} />
            </ContextMenu>
          </React.Fragment>
        ))}
    </div>
  );
};

const FileDiv = ({ file, icon, selectedFile, onClick }) => {
  const isSelected = selectedFile && selectedFile.id === file.id;
  const depth = file.depth;
  return (
    <Div depth={depth} isSelected={isSelected} onClick={onClick}>
      <FileIcon name={icon} extension={file.name.split('.').pop() || ''} />
      <span style={{ marginLeft: 2 }}>{file.name}</span>
    </Div>
  );
};

const Div = styled.div`
  display: flex;
  align-items: center;
  padding-left: ${(props) => props.depth * 16}px;
  background-color: ${(props) => (props.isSelected ? '#242424' : 'transparent')};

  :hover {
    cursor: pointer;
    background-color: #242424;
  }
`;

const DirDiv = ({ directory, selectedFile, onSelect }) => {
  let defaultOpen = false;
  if (selectedFile) defaultOpen = isChildSelected(directory, selectedFile);

  const [open, setOpen] = useState(defaultOpen);
  return (
    <>
      <ContextMenu isDir={true} path={directory.path} >
        <FileDiv
          file={directory}
          icon={open ? 'openDirectory' : 'closedDirectory'}
          selectedFile={selectedFile}
          onClick={async (e) =>setOpen(!open)}
        />
      </ContextMenu>
      {open && <SubTree directory={directory} selectedFile={selectedFile} onSelect={onSelect} />}
    </>
  );
};

const isChildSelected = (directory, selectedFile) => {
  let res = false;

  function isChild(dir, file) {
    if (selectedFile.parentId === dir.id) {
      res = true;
      return;
    }
    if (selectedFile.parentId === '0') {
      res = false;
      return;
    }
    dir.dirs.forEach((item) => {
      isChild(item, file);
    });
  }

  isChild(directory, selectedFile);
  return res;
};

const FileIcon = ({ extension, name }) => {
  let icon = getIcon(extension || '', name || '');
  return <Span>{icon}</Span>;
};

const Span = styled.span`
  display: flex;
  width: 32px;
  height: 32px;
  justify-content: center;
  align-items: center;
`;
