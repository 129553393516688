import React from 'react';



export const useFilesFromSandbox = (id, callback) => {
  React.useEffect(() => {
    fetch(process.env.REACT_APP_BASE_URL+'/dir', {
      credentials: 'include'
    })
      .then(response => response.json())
      .then((data) => {
        console.log(data);
        callback(data);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
