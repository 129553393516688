import { useEffect } from 'react';
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';
import 'react-contexify/ReactContexify.css';



export function ContextMenu({ path, isDir, children}) {
    useEffect(()=>{
        
    }, [])
  const { show } = useContextMenu({
    id: path,
  });

  function handleContextMenu(event){
      show({
        event,
        props: {
            key: 'value'
        }
      })
  }

  // I'm using a single event handler for all items
  // but you don't have too :)
  const handleItemClick = ({ id, event, props }) => {
    switch (id) {
      case "dir":        
        
        window.parent.postMessage({command:'buldFromHere', path}, '*');
        break;      
      //etc...
    }
  }

  return (
    <>
    <span onContextMenu={handleContextMenu}>{children}</span>  
    <Menu style={{marginTop:'1em'}} id={path}>       
        <Item id="dir" onClick={handleItemClick}>Start from here</Item>                  
    </Menu>
    </>
  );
}