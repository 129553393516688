// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filter-button-wrapper {
    display: flex;
    justify-content: space-around;
}

.filter-button-wrapper .filter-button{
    background: #8dc72f;
    width:auto;
    padding: 5px;
}

`, "",{"version":3,"sources":["webpack://./src/editor/FilterButtons/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,mBAAmB;IACnB,UAAU;IACV,YAAY;AAChB","sourcesContent":[".filter-button-wrapper {\n    display: flex;\n    justify-content: space-around;\n}\n\n.filter-button-wrapper .filter-button{\n    background: #8dc72f;\n    width:auto;\n    padding: 5px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
