import React, { useState, useEffect, useContext } from "react";
import Sidebar from "./components/sidebar";
import { useFilesFromSandbox, } from "./utils";
import { Code } from "./editor/code";
import styled from "@emotion/styled";
import "./App.css";
import { FileTree, } from "./components/file-tree";
import { findFileByName } from "./utils/file-manager";
import { Comments } from "./CommentsContext";
import axios from "axios";


const CURRENT_SANDBOX_ID = "ww9kis";

const dummyDir = {
  id: "1",
  name: "loading...",
  type: "DUMMY",
  parentId: undefined,
  depth: 0,
  dirs: [],
  files: []
};



const App = () => {
  const [rootDir, setRootDir] = useState(dummyDir);
  const [selectedFile, setSelectedFile] = useState(undefined);
  const { comments, setComments } = useContext(Comments)
  useFilesFromSandbox(CURRENT_SANDBOX_ID, (root) => {
    if (!selectedFile) {
      setSelectedFile(findFileByName(root, "index.tsx"));
    }
    setRootDir(root);
  });

  const getAllComments = async () => {
    const { data } = await axios(process.env.REACT_APP_BASE_URL + '/getAllComments', { withCredentials: true })
    console.log(data);
    setComments(data)
  }
  useEffect(() => {
    getAllComments()
    window.addEventListener('message', (event)=>{
      if(event.data==='getComments'){
        getAllComments()
        console.log('getComments');
      }
    });
  }, [])

  const onSelect = (file) => setSelectedFile(file);

  return (
    <div>
      <Main>
        <Sidebar>
          <FileTree
            rootDir={rootDir}
            selectedFile={selectedFile}
            onSelect={onSelect}
          />
        </Sidebar>
        <Code {...{selectedFile, setSelectedFile, rootDir, setRootDir}} />
      </Main>
    </div>
  );
};

const Main = styled.main`
  display: flex;
`;

export default App;
