import React from 'react';
import "./style.css"


const FilterButtons = ({ setFilter, path, }) => {    
    const handleOnlyGpt = () => setFilter(prev => v=> v.path === path && v.id === undefined);
    const handleOnlyGit = () => setFilter(prev => v=> v.path === path && v.id !== undefined);
    const handleAll = () => setFilter(prev => v=> v.path === path );    
    return   (
        <div className="filter-button-wrapper">

            <div className="check">
                <input type="radio" name="filter-comments" id="only-gpt" onChange={handleOnlyGpt} />
                <label htmlFor="only-gpt">Only GPT</label>
            </div>
            <div className="check">
                <input type="radio" name="filter-comments" onChange={handleOnlyGit} id="only-git" />
                <label htmlFor="only-git">Only git</label>
            </div>
            <div className="check">
                <input type="radio" name="filter-comments"  onChange={handleAll} id="all" />
                <label htmlFor="all"  >all</label>
            </div>

        </div>
    );
};

export default FilterButtons;
