import React, { useState } from 'react';

export const Comments = React.createContext();

export  function CommentsContext ({ children }) {
    const [comments, setComments] = useState([])
    const [showAllComments, setShowAllComments] = useState(false)
    return <Comments.Provider value={{comments, setComments, showAllComments, setShowAllComments}}>{children} </Comments.Provider>
}
 
