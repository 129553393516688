import React, { useEffect, useRef, useState, useContext } from 'react';
import styled from "@emotion/styled";
import CommentPopup from './CommentPopup';
import { Comments } from '../CommentsContext';
import { saveFile, setEditor } from '../utils/editor-helper';
import axios from 'axios';

export const Code = ({ selectedFile, setSelectedFile, rootDir, setRootDir }) => {
  const editorRef = useRef(null);
  const [showComment, setShowComment] = useState(false)
  const [selection, setSelection] = useState(false)
  const { comments, setComments, setShowAllComments, showAllComments } = useContext(Comments)
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const save = async (e)=>{
    if (!((e.ctrlKey || e.metaKey) && e.key === 's')) return;      
      e.preventDefault();
      await saveFile({selectedFile, setSelectedFile})
    }

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    setEditor({ editorRef, selectedFile, setSelectedFile, comments, setComments, setShowComment, setShowAllComments, setSelection })
    
    return () => {
      if (window.monacoEditor) {
        window.monacoEditor.dispose()        
        window.monacoEditor = null
      }
      
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedFile, comments, windowSize]);

  return <>
    {showComment && (
      <>
        <CommentPopup {...{ setShowComment, selection, selectedFile, windowSize }} />
      </>
    )}
    <>
      {showAllComments && (
        <>
          <CommentPopup {...{ setShowComment,  selectedFile, comments, windowSize }} />
        </>
      )}
    </>
    <div ref={editorRef} id="mainEditor" className="editor" onKeyDown={save}></div>
  </>
}

const Div = styled.div`
  width: calc(100% - 250px);
  margin: 0;
  font-size: 16px;
`;
